// Migrated
<template lang="pug">
div
  ResponsiveText.box-title.mb-4.mb-md-5
    span {{ $t('lastminute') }}

  OverflowFade.charter-last-minute__offer.white-bg.w-100(container-classes="w-100")
    nuxt-link.row.mx-0.offer(
      v-for="(lastminute, idx) in packages"
      :key="idx"
      :to="lastminute.search_url"
    )
      .col-7.d-flex.flex-column.flex-md-row.align-items-md-center.pl-0.pl-sm-4
        .offer-days.pr-3.pl-lg-3.pt-3.pb-2.pb-sm-3
          .offer-day
            .month.text-break {{ capitalize(localeDate(lastminute.date, 'dddd DD MMM')) }}

        .px-0.py-sm-2.px-lg-3
          .trip-title.mb-0.font-weight-normal
            strong {{ lastminute.name }}
            span(v-if="lastminute.days") {{ ' - ' }} {{ $t('daysText', lastminute.days) }}
          span {{ airportName(lastminute.airport) }}

      .col-5.d-inline-flex.justify-content-end
        .offer-price-bar.d-flex.flex-column.flex-md-row.pr-0.pr-lg-3
          .mr-md-4.mr-lg-5.text-align-right.py-3
            .d-flex.align-items-end
              .text-nowrap.mr-2.mr-md-3(v-if="lastminute.isDiscounted")
                small {{ $t('shortFromPrice') }}
                small.text-nowrap.strikethrough {{ $n(lastminute.regular_pricepp) }}
              .text-nowrap(:class="{ 'text-red': lastminute.isDiscounted }")
                small {{ $t('shortFromPrice') }}
                strong.text-nowrap.h5 {{ $n(lastminute.price_sek) }}

            span.mt-n1 {{ $t('perPerson') }}

          .mt-mxl-2
            button.btn-orange.btn-lg.btn-rounded.px-4.px-lg-5 {{ $t('lastMinute.ChooseOffer') }}
</template>

<script>
import { mapActions, mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { localeDate } = useDate()
    return {
      localeDate
    }
  },
  computed: {
    ...mapState(useCharterStore, {
      startpage: 'startpage',
    }),

    ...mapState(useRootStore, {
      airports: 'charterAirports',
    }),

    promotedLastminuteTypesafe () {
      const items = this.startpage?.promoted_lastminute || []
      if (items && Array.isArray(items)) {
        return items
      } else if (items && items?.['1']) {
        return Object.values(items)
      }

      return []
    },

    packages () {
      return [...this.promotedLastminuteTypesafe]
        .sort((a, b) => this.$dayjs(a.date).unix() - this.$dayjs(b.date).unix())
        .map(p => ({
          ...p,
          name: p.name?.trim().split(',').slice(0, 2).join(', '),
          isDiscounted: p.price_sek !== p.regular_pricepp
        }))
    }
  },

  methods: {
    capitalizeFirstLetter,

    airportName (airport) {
      return this.airports.find(a => a.airport === airport)?.airport_name
    },

    ...mapActions(useCharterPackagesStore, {
      searchDealfinder: 'searchDealfinder',
      activateLastMinute: 'activateLastMinute',
      search: 'search'
    }),
  }
})
</script>

<style lang="scss" scoped>
.charter-last-minute__offer {
  max-height: 690px;

  .offer-days {
    display: flex;
    gap: 1rem;
    width: 140px;

    .offer-day {
      display: flex;

      .day {
        line-height: 1;
        font-size: 2.5rem;
        font-weight: bold;
      }
      .day-name {
        line-height: 1;
        color: $gray;
      }
    }
  }

  .offer {
    border-top: 1px solid $gray;
    color: unset;

    &:hover {
      button {
        background: $blue;
        color: white;
      }
    }
  }

  .offer-price-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    position: relative;
    padding: 1.2rem 1rem;
    height: 100%;

    @media (max-width: ($md - 1px)) {
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      flex-direction: initial;
      justify-content: end;
    }
  }
}

.solresor-index__lastminute {
  h2.box-title.c-orange {
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1)
  }
  .charter-search__btn {
    height: auto;
    min-height: 120px;
  }
  &__change {
    background: $orange;
    margin: 1rem -1rem 0;
    font-size: .8em;
    padding: .5rem .75rem;
  }
}

.trip-title {
  .charter-last-minute__offer & {
    font-size: 1rem;
    line-height: 1.1;
  }
}
</style>
